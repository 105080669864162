.scoreboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;  /* Light gray background */
  border-radius: 8px;         /* Rounded corners */
  padding: 16px;             /* Add some padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Subtle shadow for depth */
}

.score-entry {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: space-evenly;
  margin: 8px;               /* Add space between entries */
  border-radius: 4px;        /* Slightly rounded corners for entries */
}