.game-dice {
  width: 60px;
  height: 60px;
  position: relative;
  transition: transform 0.3s ease;
  cursor: pointer;
  perspective: 1000px;
}

.game-dice.selected {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.5);
}

.game-dice-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.game-dice.rolling {
  animation: rollAndMove 1s ease-out forwards;
}

@keyframes rollAndMove {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }
  
  50% {
    transform: 
      rotate(calc(360deg * var(--rotations))) 
      translate(var(--x-offset), var(--y-offset));
  }
  
  100% {
    transform: 
      rotate(0deg) 
      translate(var(--final-x), var(--final-y));
  }
}

.game-dice-container {
  display: flex;
  flex-direction: column;  /* Change to column to stack elements vertically */
  align-items: center;     /* Center items horizontally */
  justify-content: center;
  width: 100%;
  padding: 5px;
  margin: 20px 0;
  overflow: hidden;
}

.dice-row {
  display: flex;          /* New class for the dice wrapper */
  justify-content: center;
  gap: 20px;             /* Space between dice */
  margin-bottom: 20px;   /* Space before the button */
}

.controls-row {
  display: flex;          /* New class for the controls wrapper */
  flex-direction: column;
  align-items: center;
  gap: 10px;             /* Space between button and roll count */
}