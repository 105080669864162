/* Game Page Styling */
.game-container {
    min-height: 100vh;
    background-color: #3aa416;
    position: relative;
    overflow: hidden;
    padding: 20px;
}

/* Content organization */
.game-organize {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    z-index: 10;
    margin-bottom: 25px;
}

.game-board {
    width: 100%;
}

/* Make all cards semi-transparent white with shadow */
.ant-card {
    background-color: rgba(255, 255, 255, 0.95) !important;
    border-radius: 15px !important;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2) !important;
}

/* Specific styling for game components */
.scoreboard-container,
.gameboard-container {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    padding: 20px;
    flex: 1;
    min-width: 300px;
}

.scoreboard-container {
    max-width: 400px;
}

.gameboard-container {
    max-width: 500px;
}

/* Card headers */
.ant-card-head {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.ant-card-head-title {
    font-weight: bold !important;
}
