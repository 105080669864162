/* Floating Dice */
.dice-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0; /* Ensure dice are behind the content */
  }
  
  .dice {
    position: absolute;
    width: 50px;
    height: 50px;
    background-image: url('../img/dice.gif'); /* Path to your dice GIF */
    background-size: cover;
    animation: float-around 12s infinite ease-in-out;
  }
  
  /* Create unique animations for each dice */
  .dice:nth-child(1) { top: 10%; left: 20%; animation-duration: 10s; animation-delay: 0s; }
  .dice:nth-child(2) { top: 30%; left: 60%; animation-duration: 12s; animation-delay: 2s; }
  .dice:nth-child(3) { top: 50%; left: 40%; animation-duration: 14s; animation-delay: 1s; }
  .dice:nth-child(4) { top: 70%; left: 80%; animation-duration: 16s; animation-delay: 3s; }
  .dice:nth-child(5) { top: 20%; left: 10%; animation-duration: 9s; animation-delay: 1.5s; }
  .dice:nth-child(6) { top: 80%; left: 30%; animation-duration: 13s; animation-delay: 2.5s; }
  .dice:nth-child(7) { top: 40%; left: 70%; animation-duration: 11s; animation-delay: 0.5s; }
  .dice:nth-child(8) { top: 60%; left: 50%; animation-duration: 15s; animation-delay: 4s; }
  .dice:nth-child(9) { top: 15%; left: 85%; animation-duration: 8s; animation-delay: 3.5s; }
  .dice:nth-child(10) { top: 5%; left: 50%; animation-duration: 17s; animation-delay: 2s; }
  
  /* Floating animation */
  @keyframes float-around {
    0% { transform: translate(0, 0) rotate(0deg); }
    25% { transform: translate(50vw, -20vh) rotate(90deg); }
    50% { transform: translate(-50vw, 20vh) rotate(180deg); }
    75% { transform: translate(50vw, -10vh) rotate(270deg); }
    100% { transform: translate(0, 0) rotate(360deg); }
  }
  