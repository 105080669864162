/* Auth Page Styling */
.auth-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #3aa416;
    margin: 0;
    position: relative;
    overflow: hidden;
}

/* Auth Container Styling */
.auth-container {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    z-index: 10;
    position: relative;
    max-width: 400px; /* Limit the width for desktop */
}

/* Title and Button Styling */
.auth-container h1, 
.auth-container h3 {
    text-align: center;
}

.auth-container button {
    width: 100%; /* Full width buttons for small screens */
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .auth-container {
        padding: 15px; /* Reduce padding on smaller screens */
    }

    .auth-container h1,
    .auth-container h3 {
        font-size: 1.5rem; /* Adjust title font size */
    }

    .auth-container button {
        font-size: 0.9rem; /* Adjust button font size */
    }
}

@media screen and (max-width: 480px) {
    .auth-container {
        padding: 10px;
    }

    .auth-container h1,
    .auth-container h3 {
        font-size: 1.2rem;
    }

    .auth-container button {
        font-size: 0.8rem;
    }
}
